import React, { useState, useEffect, Fragment } from "react";
import axios from 'axios';
import Head from 'next/head';
import Link from 'next/link';
import { getSession } from 'next-auth/client';
import { Bulletin, HomeContainer, Ad, MobileInsertAd, HomeQuickSearch, Seo } from '@app/components';
import { getHomepageJson } from '@app/utils'

import { renderHomeCard } from '@app/utils/shortcodeUtils';
import getRawBody from 'raw-body';

import { prettifyJson } from 'utils/jsonUtils';
import { getResponseBody, deserializeResponseBodyColumns } from "@app/utils/homepageUtils";

// TODO: remove ping endpoint when debug is not needed.
export async function getServerSideProps(context) {
  const session = await getSession(context);
  // const { data } = await axios.get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/ping`);
  var req = context.req;
  let res = null;
  let isPreview = false;
  if (req.method === "GET") {
    // res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/cms/homepage`);
    res = await getHomepageJson()
    //console.debug('GET', res.result);
    //console.debug('GET', res.result[0].html);
  } else if(req.method === "POST") {
    isPreview = true;
    const rawBody = await getRawBody(req)
    const requestBody = JSON.parse(rawBody.toString("utf-8"))
    res = await getResponseBody(requestBody);
    res = deserializeResponseBodyColumns(res);
    console.debug('POST', res.result);
  }

  
  return {
    props: {
      isPreview,
      // ping: data,
      session : session,
      payloads: [
        {
          section: 'Center Top', html: [
            {
              type: 'HomeTopStoryGroup',
              jsonContent: []
            }
          ]
        }
      ],
      payloads: res.result,
      // rawHtml: {
      //   left: res?.data?.result?.find(x => x.section === "Left").html || '',
      //   center: res?.data?.result?.find(x => x.section === "Center").html || '',
      //   right: res?.data?.result?.find(x => x.section === "Right").html || '',
      // }
    }
  }
}

function RenderColumn(payloads, section) {
  return payloads.find(x => x.section === section)?.html.map((item, idx) => <Fragment key={`home-col-${idx}`}>
    {renderHomeCard(item, section)}
  </Fragment>)
}

export default function Home(props) {
  const { session, isPreview } = props;
  // console.log('home',session)
  // const [keyword, keywordInput] = useState(" ")

  // const handleInput = event => keywordInput(event.target.value)

  const LeftTopSection = () => props.payloads.find(x => x.section === 'Left Top') && <section data-section="left-top">{RenderColumn(props.payloads, "Left Top")}</section> || '';
  const LeftBottomSection = () => props.payloads.find(x => x.section === 'Left Bottom') && <section data-section="left-bottom">{RenderColumn(props.payloads, "Left Bottom")}</section> || '';
  const CenterTopSection = () => <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-16'>
    <section data-section="center-top">{RenderColumn(props.payloads, "Center Top")}</section>
    {/* Ad & Bulletin */}
    <section className='tw-flex tw-flex-col tw-flex-warp tw-gap-8 | sm:tw-gap-16 | xl:tw-flex-row xl:tw-flex-nowrap'>
      <div className='tw-hidden tw-h-ad tw--mx-2 tw-bg-secondary-light | sm:tw-block sm:tw-h-auto sm:tw-mx-0 sm:tw-bg-transparent'>
        <div className='tw-sticky tw-top-2 | sm:tw-static'>
          <Ad googleAd type='house' />
        </div>
      </div>

      <Bulletin />
    </section>
  </div>
  const CenterBottomSection = () => props.payloads.find(x => x.section === 'Center Bottom') && <section data-section="center-bottom">{RenderColumn(props.payloads, "Center Bottom")}</section> || '';
  const RightTopSection = () => props.payloads.find(x => x.section === 'Right Top') && <section data-section="right-top">{RenderColumn(props.payloads, "Right Top")}</section> || '';
  const RightBottomSection = () => props.payloads.find(x => x.section === 'Right Bottom') && <section data-section="right-bottom">{RenderColumn(props.payloads, "Right Bottom")}</section> || '';

  return (
    <>
      <Seo.SiteLinksSearchBox />
      <Head>
        <title>AsianInvestor - The network for Asian investment management in Asia Pacific</title>
        {/* <title>{props.ping} | </title> */}
        {
          isPreview && <style>{'body { margin-top: 50px !important; }'}</style>
        }
      </Head>
      {
        isPreview && <div className="tw-z-50 tw-bg-[#170e5d] tw-h-[50px] tw-text-[32px] tw-fixed tw-top-0 tw-left-0 tw-w-full tw-text-[#ee0000] tw-capitalize tw-font-bold tw-border-b-2 tw-px-4 tw-font-serif">homepage preview</div>
      }
      <HomeContainer googleAd
        leftTop={<LeftTopSection />}
        leftBottom={<LeftBottomSection />}
        centerTop={<CenterTopSection />}
        centerBottom={<CenterBottomSection />}
        rightTop={<RightTopSection />}
        rightBottom={<RightBottomSection />}
      />
    </>
  )
}
